import { createPlacementPresignedUrlResponse } from "@clipboard-health/contract-worker-app-bff";
import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";

interface UseUploadPayDataParams {
  file: File;
}

export interface UploadPayDataResponse {
  fileName: string;
}

export function useUploadPayData(
  options?: UseMutationOptions<UploadPayDataResponse, unknown, UseUploadPayDataParams>
) {
  return useMutation({
    mutationFn: async (params: UseUploadPayDataParams) => {
      logEvent(APP_V2_APP_EVENTS.PLACEMENT_PAY_DATA_UPLOAD_STARTED, {
        file: params.file.name,
      });

      const { file } = params;
      const presignedUrlResponse = await post({
        url: `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/placements/presigned-urls`,
        responseSchema: createPlacementPresignedUrlResponse,
        data: {
          data: {
            type: "placement-presigned-url",
            attributes: { mediaType: file.type },
          },
        },
      });

      await axios.put(presignedUrlResponse.data.data.attributes.url, file, {
        headers: { "Content-Type": file.type },
      });

      logEvent(APP_V2_APP_EVENTS.PLACEMENT_PAY_DATA_UPLOAD_SUCCESS, {
        file: presignedUrlResponse.data.data.id,
      });

      return {
        fileName: presignedUrlResponse.data.data.id,
      };
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PLACEMENT_PAY_DATA_UPLOAD_FAILURE,
    },
    ...options,
  });
}
