import { post } from "@src/appV2/api/api";
import { API_TYPES } from "@src/appV2/api/constants";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

const createDeepLinkRequestBodySchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.deepLink),
    attributes: z.record(z.string()),
  }),
});

const createDeepLinkResponseSchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.deepLink),
    attributes: z.object({
      deepLink: z.string(),
    }),
  }),
});
export type DeepLinkResponse = z.infer<typeof createDeepLinkResponseSchema>;

interface CreatePlacementDeepLinkParams {
  placementId: string;
  body: Record<string, string>;
}
export function useCreatePlacementDeepLink(
  options: UseMutationOptions<DeepLinkResponse, string, CreatePlacementDeepLinkParams> = {}
) {
  return useMutation({
    mutationFn: async (params) => {
      const { placementId, body } = params;

      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/placements/${placementId}/create-deep-links`,
        data: {
          data: {
            type: "deep-link",
            attributes: body,
          },
        },
        requestSchema: createDeepLinkRequestBodySchema,
        responseSchema: createDeepLinkResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_REFERRALS_FAILURE,
    },
    ...options,
  });
}
