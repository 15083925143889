import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";

import {
  type GetReferralsQuery,
  type GetReferralsResponse,
  getReferralsResponseSchema,
} from "./referral.contract";

export const referralsUrl = `${environmentConfig.REACT_APP_BASE_API_URL}/v2/referrals`;

export function getReferralsQueryKey(params: GetReferralsQuery) {
  return [referralsUrl, params];
}

export function transformResponseIntoReferrals(response: GetReferralsResponse) {
  return response.data.map((referral) => ({
    id: referral.id,
    ...referral.attributes,
  }));
}

export type ReferralDetail = ReturnType<typeof transformResponseIntoReferrals>[number];

export function useReferrals(
  params: GetReferralsQuery,
  options?: UseQueryOptions<ReferralDetail[]>
) {
  return useQuery({
    queryKey: getReferralsQueryKey(params),
    queryFn: async () => {
      const response = await get({
        url: referralsUrl,
        queryParams: params,
        responseSchema: getReferralsResponseSchema,
      });
      return transformResponseIntoReferrals(response.data);
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_REFERRALS_FAILURE,
    },
    staleTime: 1000 * 60,
    ...options,
  });
}
