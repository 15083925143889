import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";

import {
  createReferralResponseSchema,
  type CreateWorkerReferralBody,
  createWorkerReferralBodySchema,
} from "./referral.contract";

interface CreateReferralParams {
  workerId: string;
  data: CreateWorkerReferralBody["data"]["attributes"];
}
export function useCreateReferral(
  options: UseMutationOptions<void, string, CreateReferralParams> = {}
) {
  return useMutation({
    mutationFn: async (params) => {
      const { workerId, data } = params;

      await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/referrals`,
        data: {
          data: {
            type: "referral",
            attributes: data,
          },
        },
        responseSchema: createReferralResponseSchema,
        requestSchema: createWorkerReferralBodySchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_REFERRALS_FAILURE,
    },
    ...options,
  });
}
