import {
  type GetPayDataResponse,
  getPayDataResponse,
  type PlacementPay,
} from "@clipboard-health/contract-worker-app-bff";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type QueryClient } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";
import { groupBy } from "lodash";

export const GET_PLACEMENT_PAY_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/placement-pay`;

export interface UseGetPayTransparencyData {
  userHasSharedPayData: boolean;
  payDataByWorkplace: Record<string, PlacementPay[]>;
  isLoading: boolean;
  isSuccess: boolean;
}

interface GetPayTransparencyData {
  userHasSharedPayData: boolean;
  userYearsOfExperience?: number;
  payDataByWorkplace: Record<string, PlacementPay[]>;
  isLoading: boolean;
  isSuccess: boolean;
}

export function useGetPayTransparencyData(
  options?: UseGetQueryOptions<GetPayDataResponse>
): GetPayTransparencyData {
  const query = useGetQuery({
    url: GET_PLACEMENT_PAY_URL,
    responseSchema: getPayDataResponse,
    staleTime: minutesToMilliseconds(60),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PLACEMENT_PAY_FAILURE,
      userErrorMessage: "Something went wrong while loading the pay transparency data.",
    },
    ...options,
  });

  if (query.isSuccess && query.data?.meta) {
    return {
      userHasSharedPayData: query.data.meta.enabled,
      userYearsOfExperience: query.data.meta.user?.yearsOfExperience,
      payDataByWorkplace: groupBy(query.data.data, (placementPay: PlacementPay) => {
        return placementPay.relationships.workplace.data.id;
      }),
      isLoading: query.isLoading,
      isSuccess: query.isSuccess,
    };
  }

  return {
    userHasSharedPayData: false,
    payDataByWorkplace: {},
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
  };
}

export function invalidatePayTransparencyData(queryClient: QueryClient) {
  void queryClient.invalidateQueries({
    queryKey: [GET_PLACEMENT_PAY_URL],
  });
}
