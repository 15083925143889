import { post } from "@src/appV2/api/api";
import { API_TYPES } from "@src/appV2/api/constants";
import { environmentConfig } from "@src/appV2/environment";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

const interviewResponseSchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.interview),
    id: z.string(),
    attributes: z.object({
      start: z.string(),
      end: z.string(),
    }),
  }),
});

type InterviewResponse = z.infer<typeof interviewResponseSchema>;

const createInterviewBodySchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.interview),
    attributes: z.object({
      start: z.string(),
      end: z.string(),
    }),
    relationships: z.object({
      rescheduledInterview: z
        .object({
          data: z.object({
            type: z.literal(API_TYPES.interview),
            id: z.string(),
          }),
        })
        .optional(),
      placementApplication: z
        .object({
          data: z.object({
            type: z.literal(API_TYPES.placementApplication),
            id: z.string(),
          }),
        })
        .optional(),
    }),
  }),
});

const createInterviewParamsSchema = z.object({
  relatedResource: z.object({
    type: z.enum([API_TYPES.interview, API_TYPES.placementApplication]),
    id: z.string(),
  }),
  start: z.date(),
  end: z.date(),
});

type CreateInterviewParams = z.infer<typeof createInterviewParamsSchema>;

export function useCreateInterview(
  options: UseMutationOptions<InterviewResponse, string, CreateInterviewParams> = {}
) {
  return useMutation({
    mutationFn: async (params) => {
      const { relatedResource, start, end } = params;

      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/interviews`,
        data: {
          data: {
            type: API_TYPES.interview,
            attributes: {
              start: start.toISOString(),
              end: end.toISOString(),
            },
            relationships: {
              ...(relatedResource.type === API_TYPES.interview && {
                rescheduledInterview: {
                  data: {
                    type: API_TYPES.interview,
                    id: relatedResource.id,
                  },
                },
              }),
              ...(relatedResource.type === API_TYPES.placementApplication && {
                placementApplication: {
                  data: {
                    type: API_TYPES.placementApplication,
                    id: relatedResource.id,
                  },
                },
              }),
            },
          },
        },
        requestSchema: createInterviewBodySchema,
        responseSchema: interviewResponseSchema,
      });

      return response.data;
    },
    ...options,
  });
}
