import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

export function useCreateChatWithRecruiter(options?: UseMutationOptions) {
  return useMutation({
    mutationFn: async () => {
      await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/interviews/chat-with-clipboard-recruiter`,
        requestSchema: z.unknown(),
        responseSchema: z.unknown(),
      });
    },
    ...options,
  });
}
