import { API_TYPES } from "@src/appV2/api";
import { z } from "zod";

export const getReferralsQuerySchema = z.object({
  sort: z.string().optional(),
  filter: z.object({
    refereeId: z.string().optional(),
    refereeType: z.string().optional(),
    referralCodeUsed: z.string().optional(),
    referralProgram: z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
      })
      .optional(),
  }),
  page: z
    .object({
      size: z.number(),
      cursor: z.string().optional(),
    })
    .optional(),
});
export type GetReferralsQuery = z.infer<typeof getReferralsQuerySchema>;

export const REFEREE_TYPE = ["WORKER", "WORKPLACE"] as const;
export type RefereeType = (typeof REFEREE_TYPE)[number];

export const REFERRAL_STATUS = ["APPLIED", "PROCESSING", "PAID_OUT"] as const;
export type ReferralStatus = (typeof REFERRAL_STATUS)[number];

export const REFERRAL_PROGRAM_NAME = [
  "CANDIDATE_PROFILE_ACTIVATION",
  "PLACEMENT_SIGN_ON_BONUS",
] as const;
export type ReferralProgramName = (typeof REFERRAL_PROGRAM_NAME)[number];

const referBonusSchema = z.object({
  transactionId: z.string().optional(),
  amountApplied: z.number(),
  amountPaid: z.number().optional(),
});

const referralDataSchema = z.object({
  type: z.literal(API_TYPES.referral),
  id: z.string(),
  attributes: z.object({
    refereeId: z.string(),
    refereeType: z.enum(REFEREE_TYPE),
    referralCodeUsed: z.string(),
    status: z.enum(REFERRAL_STATUS),
    referrerBonus: referBonusSchema.optional(),
    refereeBonus: referBonusSchema.optional(),
    referralCodeSource: z.string(),
    referralProgram: z
      .object({
        id: z.string(),
        name: z.enum(REFERRAL_PROGRAM_NAME),
      })
      .optional(),
  }),
  relationships: z.object({
    referee: z.object({
      data: z.object({
        id: z.string(),
        type: z.enum([API_TYPES.worker, API_TYPES.workplace]),
      }),
    }),
    referrer: z.object({
      data: z.object({
        id: z.string(),
        type: z.enum([API_TYPES.worker, API_TYPES.workplaceUser]),
      }),
    }),
  }),
});

export type ReferralData = z.infer<typeof referralDataSchema>;

export const getReferralsResponseSchema = z.object({
  data: z.array(referralDataSchema.omit({ relationships: true })),
  links: z.object({
    next: z.string().optional(),
    prev: z.string().optional(),
  }),
});
export type GetReferralsResponse = z.infer<typeof getReferralsResponseSchema>;

export const createWorkerReferralBodySchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.referral),
    attributes: z.object({
      referralCodeUsed: z.string(),
      referralProgram: z.object({
        id: z.string(),
        name: z.enum(REFERRAL_PROGRAM_NAME),
      }),
    }),
  }),
});

export type CreateWorkerReferralBody = z.infer<typeof createWorkerReferralBodySchema>;

const referralIncludedWorkerResponseSchema = z.object({
  type: z.literal(API_TYPES.worker),
  id: z.string(),
  attributes: z.object({ name: z.string(), email: z.string() }),
});
export type ReferralIncludedWorkerResponse = z.infer<typeof referralIncludedWorkerResponseSchema>;

const referralIncludedWorkplaceUserResponseSchema = z.object({
  type: z.literal(API_TYPES.workplaceUser),
  id: z.string(),
  attributes: z.object({ name: z.string(), email: z.string() }),
  relationships: z.object({
    workplace: z.object({
      data: z.object({
        id: z.string(),
        type: z.literal(API_TYPES.workplace),
      }),
    }),
  }),
});
export type ReferralIncludedWorkplaceUserResponse = z.infer<
  typeof referralIncludedWorkplaceUserResponseSchema
>;

const referralIncludedWorkplaceResponseSchema = z.object({
  type: z.literal(API_TYPES.workplace),
  id: z.string(),
  attributes: z.object({
    name: z.string(),
    address: z.object({
      formatted: z.string().optional(),
    }),
  }),
});
export type ReferralIncludedWorkplaceResponse = z.infer<
  typeof referralIncludedWorkplaceResponseSchema
>;

const referralIncludedResponseSchema = z.discriminatedUnion("type", [
  referralIncludedWorkerResponseSchema,
  referralIncludedWorkplaceUserResponseSchema,
  referralIncludedWorkplaceResponseSchema,
]);
export type ReferralIncludedResponse = z.infer<typeof referralIncludedResponseSchema>;

export const createReferralResponseSchema = z.object({
  data: referralDataSchema,
  included: z.array(referralIncludedResponseSchema),
});
export type CreateReferralResponse = z.infer<typeof createReferralResponseSchema>;
