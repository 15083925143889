import { patch } from "@src/appV2/api/api";
import { API_TYPES } from "@src/appV2/api/constants";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const updateInterviewBodySchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.interview),
    id: z.string(),
    attributes: z.object({
      status: z.string(),
    }),
  }),
});

export type UpdateInterviewBody = z.infer<typeof updateInterviewBodySchema>;

interface UpdateInterviewParams {
  interviewId: string;
  attributes: {
    status: string;
  };
}

export function useUpdateInterview(
  options: UseMutationOptions<void, AxiosError, UpdateInterviewParams> = {}
) {
  return useMutation({
    mutationFn: async ({ interviewId, attributes }) => {
      await patch({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/interviews/${interviewId}`,
        data: {
          data: {
            type: API_TYPES.interview,
            id: interviewId,
            attributes,
          },
        },
        requestSchema: updateInterviewBodySchema,
        responseSchema: z.unknown(),
      });

      logEvent(APP_V2_APP_EVENTS.UPDATE_INTERVIEW_SUCCESS, {
        interviewId,
        attributes,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.UPDATE_INTERVIEW_FAILURE,
    },
    ...options,
  });
}
