import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

// These schemas can be removed once the worker-app-bff PR is merged
export const submitPayDataRequest = z.object({
  data: z.object({
    type: z.literal("pay-data"),
    attributes: z.object({
      fileName: z.string().min(1),
    }),
  }),
});

export const submitPayDataResponse = z.object({
  data: z.object({
    type: z.literal("pay-data"),
    id: z.string(),
    attributes: z.object({
      enabled: z.boolean(),
    }),
  }),
});

type SubmitPayDataRequest = z.infer<typeof submitPayDataRequest>;
type SubmitPayDataResponse = z.infer<typeof submitPayDataResponse>;

interface UseSubmitPayDataParams {
  fileName: string;
}

export function useSubmitPayData() {
  return useMutation({
    mutationFn: async (params: UseSubmitPayDataParams) => {
      const response = await post<SubmitPayDataRequest, SubmitPayDataResponse>({
        url: `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/pay-data`,
        responseSchema: submitPayDataResponse,
        data: {
          data: { type: "pay-data", attributes: { fileName: params.fileName } },
        },
      });

      return response.data;
    },
  });
}
